import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      '"Tome una buena desición eligiendo Fit Club, ¡gracias a ellos pude alcanzar mi meta soñada y vivir una vida acorde!"',
    name: 'LUCAS MALTESE',
    status : 'EMPRESARIO'
  },
  {
    image: image2,
    review: '"Sin la ayuda de Fit club no hubiese podido realizar mi record mundial de press de banca, agradezco a los coaches y nutricionistas."',
    name: 'KEVIN ARANDA',
    status: 'COACH'
  },
  {
    image : image3,
    review: '"Mi esposa y yo estamos muy contentos de unirnos a Fit Club, tenemos una vida más saludable y se nos crearon nuevos habitos"',
    name: 'CRISTIAN MARCELO',
    status: "CUSTOMER"
  }
];
