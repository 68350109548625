import React from "react";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";
import nb from '../../assets/nb.png';
import adidas from '../../assets/adidas.png'
import nike from '../../assets/nike.png'
import tick from "../../assets/tick.png";
import "./Reasons.css";
const Reasons = () => {
  return (
    <div className="Reasons" id='reasons'>
      <div className="left-r">
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
      </div>
      <div className="right-r">
        <span>Algunas razones</span>
        <div>
          <span className="stroke-text">¿Por qué </span>
          <span>elegirnos?</span>
        </div>
        <div className="details-r">
          <div>
            <img src={tick} alt="" />
            <span>más de 140+ coaches expertos</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>Entrena inteligente y más fuerte</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>1 programa gratis por nuevo miembro</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>Los mejores nos eligen</span>
          </div>
        </div>
        <span style={{color: "var(--gray)", fontWeight: "normal"}}>Nuestras marcas</span>
        <div className="partners">
              <img src={nb} alt="" />
              <img src={adidas} alt="" />
              <img src={nike} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Reasons;
